<template>
  <div id="test2">
    <div class="crumbs">垃圾分类 / 设备管理</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="搜索">
              <el-input
                placeholder="请输入地址或投放点名称"
                v-model="keywords"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属街镇">
              <el-select v-model="search_street_id" placeholder="请选择">
                <el-option :label="'全部'" :value="''"> </el-option>
                <el-option
                  v-for="streetItem in StreetList"
                  :label="streetItem.street_name"
                  :value="streetItem.street_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作状态">
              <el-select v-model="work_status" placeholder="请选择">
                <el-option :label="'全部'" :value="''"> </el-option>
                <el-option :label="'正常'" :value="1"> </el-option>
                <el-option :label="'故障'" :value="0"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="连接状态">
              <el-select v-model="connect_status" placeholder="请选择">
                <el-option :label="'全部'" :value="''"> </el-option>
                <el-option :label="'运行中'" :value="1"> </el-option>
                <el-option :label="'已离线'" :value="0"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="medium"
                @click="dialogVisibleToggle(true)"
                >添加</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column
            prop="point_id"
            label="编号"
            :align="'center'"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="point_name"
            label="投放点名称"
            :align="'center'"
          >
          </el-table-column>

          <el-table-column prop="address" label="投放点地址" :align="'center'">
          </el-table-column>
          <el-table-column
            prop="terminal_code"
            label="监控序列号"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column label="连接状态" :align="'center'">
            <template slot-scope="scope">
              <div v-if="scope.row.connect_status">
                <el-radio
                  v-model="scope.row.connect_status"
                  :label="0"
                  :disabled="scope.row.connect_status === 0 ? false : true"
                  >已离线</el-radio
                >
                <el-radio
                  v-model="scope.row.connect_status"
                  :label="1"
                  :disabled="scope.row.connect_status === 1 ? false : true"
                  >运行中</el-radio
                >
              </div>
              <div v-else>
                <span>{{ scope.row.offline_reason }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="满溢状态" :align="'center'" width="240">
            <template slot-scope="scope">
              <div>
                <span>满溢:{{ scope.row.full_info.b_full }}个</span>
                <span style="margin: 0 5px"
                  >未满:{{ scope.row.full_info.b_not_full }}个</span
                >
                <span>空桶:{{ scope.row.full_info.b_empty }}个</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="运行状态" width="250" :align="'center'">
            <template slot-scope="scope">
              <div
                @click="pointWorkBreakSignShowFn(scope.row.point_id)"
                v-if="scope.row.work_status"
              >
                <el-radio
                  v-model="scope.row.work_status"
                  :label="0"
                  :disabled="scope.row.work_status === 0 ? false : true"
                  >故障</el-radio
                >
                <el-radio
                  v-model="scope.row.work_status"
                  :label="1"
                  :disabled="scope.row.work_status === 1 ? false : true"
                  >正常</el-radio
                >
              </div>

              <div v-else @click="pointWorkBreakSignShowFn(scope.row.point_id)">
                故障原因:{{ scope.row.break_reason }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="地埋桶配置" :align="'center'" width="310">
            <template slot-scope="scope">
              <div class="abc">
                <div
                  v-for="(item, index) in scope.row.barrel_info"
                  :key="index"
                  @click="clickbindid(scope.row, item.barrel_type)"
                >
                  <el-radio
                    v-model="item.is_set"
                    :label="item.is_set > 0 ? item.is_set : 99"
                    :disabled="item.is_set >= 1 ? false : true"
                    >{{ typelist[index] }}&nbsp;{{ item.is_set }}个</el-radio
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="手动控制"
            width="250"
            :align="'center'"
            v-if="$store.state.userphone == 'byt'"
          >
            <template slot-scope="scope">
              <div @click="handleControlColor(scope.row)">
                <el-radio
                  v-model="scope.row.hand_movement"
                  :label="0"
                  :disabled="scope.row.hand_movement === 0 ? false : true"
                  >关闭</el-radio
                >
                <el-radio
                  v-model="scope.row.hand_movement"
                  :label="1"
                  :disabled="scope.row.hand_movement === 1 ? false : true"
                  >开启</el-radio
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" :align="'center'">
            <template slot-scope="scope">
              <el-button type="text" @click="editLaunchpoint(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="remove(scope.row.point_id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="list_counts"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>
    <el-dialog title="添加投放点" :visible.sync="dialogVisible" width="30%">
      <el-form
        label-width="100px"
        :model="formLabelAlign"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="投放点名称:" prop="point_name">
          <el-input v-model="formLabelAlign.point_name"></el-input>
        </el-form-item>
        <el-form-item label="经度:">
          <div style="display: flex">
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.longitudeDegree"
                placeholder="度"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.longitudeBranch"
                placeholder="分"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.longitudeSecond"
                placeholder="秒"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="纬度:">
          <div style="display: flex">
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.latitudeDegree"
                placeholder="度"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.latitudeBranch"
                placeholder="分"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.latitudeSecond"
                placeholder="秒"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <AreaSelect ref="_Area" street_type="select" :areadata="areaData" />

        <el-form-item label="投放点地址:" prop="address">
          <el-input v-model="formLabelAlign.address"></el-input>
        </el-form-item>
        <el-form-item label="监控序列号:">
          <el-input v-model="formLabelAlign.terminal_code"></el-input>
        </el-form-item>
        <el-form-item label="地埋桶配置:">
          <div>
            有害垃圾:
            <el-input-number
              v-model="num0"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>

          <div>
            可回收物:
            <el-input-number
              v-model="num1"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
          <div>
            其他垃圾:
            <el-input-number
              v-model="num2"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
          <div>
            厨余垃圾:
            <el-input-number
              v-model="num3"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addLaunchpoint">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑投放点" :visible.sync="edit" width="30%">
      <el-form label-width="100px" :model="editfromdata">
        <el-form-item label="投放点名称:">
          <el-input v-model="editfromdata.point_name"></el-input>
        </el-form-item>
        <AreaSelect ref="_Area" street_type="select" :areadata="areaData" />
        <el-form-item label="经度:">
          <div style="display: flex">
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.longitudeDegree"
                placeholder="度"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.longitudeBranch"
                placeholder="分"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.longitudeSecond"
                placeholder="秒"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="纬度:">
          <div style="display: flex">
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.latitudeDegree"
                placeholder="度"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.latitudeBranch"
                placeholder="分"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="EnclosureData.latitudeSecond"
                placeholder="秒"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="投放点地址:">
          <el-input v-model="editfromdata.address"></el-input>
        </el-form-item>
        <el-form-item label="监控序列号:">
          <el-input v-model="editfromdata.terminal_code"></el-input>
        </el-form-item>
        <el-form-item label="地埋桶配置:">
          <div>
            有害垃圾:
            <el-input-number
              v-model="num0"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
          <div>
            可回收物:
            <el-input-number
              v-model="num1"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
          <div>
            其他垃圾:
            <el-input-number
              v-model="num2"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
          <div>
            厨余垃圾:
            <el-input-number
              v-model="num3"
              controls-position="right"
              :min="0"
              :max="10"
              size="mini"
            ></el-input-number>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit = false">取 消</el-button>
        <el-button type="primary" @click="seteditLaunchpoint">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="绑定设备ID" :visible.sync="bindidisshow">
      <el-form :model="bindiddata" label-width="100px">
        <el-form-item label="操作选项">
          <el-select v-model="bindiddata.isbind" placeholder="请选择绑定/解绑">
            <el-option label="绑定" :value="1"></el-option>
            <el-option label="解绑" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备ID">
          <el-select
            v-model="item.device_id"
            placeholder="请选择/输入设备ID"
            v-for="item in bindiddata.barrel_idarr"
            :key="item.barrel_id"
            filterable
          >
            <el-option
              v-for="item in nobingarr"
              :label="item.device_id"
              :value="item.device_id"
              :key="item.device_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="setbindid">绑定/解绑</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindidisshow = false">取 消</el-button>
        <el-button type="primary" @click="bindidisshow = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="投放点设备故障登记/恢复"
      :visible.sync="pointWorkBreakSignShow"
    >
      <el-form :model="pointWorkBreakSignData">
        <el-form-item label="故障登记/恢复" label-width="150px">
          <el-select v-model="pointWorkBreakSignData.type" placeholder="请选择">
            <el-option :label="'登记故障'" :value="1"> </el-option>
            <el-option :label="'恢复正常'" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障原因" label-width="150px">
          <el-input
            v-model="pointWorkBreakSignData.break_reason"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pointWorkBreakSignShow = false">取 消</el-button>
        <el-button type="primary" @click="pointWorkBreakSignFn"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="手动控制点位颜色" :visible.sync="controlColorShow">
      <el-form :model="controlColorData">
        <el-form-item label="开关" label-width="150px">
          <el-select
            v-model="controlColorData.hand_movement"
            placeholder="请选择"
          >
            <el-option :label="'开启'" :value="1"> </el-option>
            <el-option :label="'关闭'" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色" label-width="150px">
          <el-select
            v-model="controlColorData.point_full_status"
            placeholder="请选择"
          >
            <el-option :label="'绿色'" :value="1"> </el-option>
            <el-option :label="'黄色'" :value="2"> </el-option>
            <el-option :label="'红色'" :value="3"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="controlColorShow = false">取 消</el-button>
        <el-button type="primary" @click="handleControlColorFnBB"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetPointList,
  addEditPoint,
  getGrantStreetList,
  DeletePoint,
  getPointBarrelList,
  getDeviceIdList,
  bindDeviceForBarrel,
  pointWorkBreakSign,
  setManualControl,
  changePointColor,
} from "../request/api";
import AreaSelect from "../components/AreaSelect/AreaSelect.vue";
export default {
  name: "DemoTest2",
  components: {
    AreaSelect,
  },
  data() {
    return {
      controlColorShow: false,
      controlColorData: {
        point_id: "",
        hand_movement: 1,
        old_hand_movement: 0,
        point_full_status: 1,
      },
      tableData: [],
      typelist: ["有害垃圾", "可回收物", "其他垃圾", "厨余垃圾"],
      num0: 0, //有害垃圾桶数量
      num1: 0, //可回收物桶数量
      num2: 0, //其他垃圾桶数量
      num3: 0, //厨余垃圾桶数量
      list_counts: 0, //总数
      page: 1, //当前页
      limit: 10, //一页展示几条
      dialogVisible: false,
      edit: false,
      bindidisshow: false,
      bindiddata: {
        isbind: 1,
        barrel_id: null,
        device_id: null,
        barrel_idarr: [],
      },
      nobingarr: [],
      editfromdata: {},
      formLabelAlign: {
        point_id: null,
        point_name: "",
        longitude: "",
        latitude: "",
        address: "",
        terminal_code: "",
        barrel_types: null,
        street_id: null,
        checkList: [],
      },
      StreetList: null,
      rules: {
        point_name: {
          required: true,
          message: "请输入投放点名称",
          trigger: "blur",
        },
        longitude: { required: true, message: "请输入经度", trigger: "blur" },
        latitude: { required: true, message: "请输入纬度", trigger: "blur" },
        address: {
          required: true,
          message: "请输入投放点地址",
          trigger: "blur",
        },
        street_id: {
          required: true,
          message: "请选择所属街镇",
          trigger: "blur",
        },
      },
      keywords: "",
      work_status: "",
      search_street_id: "",
      connect_status: "",
      pointWorkBreakSignShow: false,
      pointWorkBreakSignData: {
        point_id: "",
        break_reason: "",
        type: 1,
      },
      EnclosureData: {
        longitudeDegree: null, //经度的度
        longitudeBranch: null, //经度的分
        longitudeSecond: null, //经度的秒
        latitudeDegree: null, //纬度的度
        latitudeBranch: null, //纬度的分
        latitudeSecond: null, //纬度的秒
      },
      areaData: {
        province: "",
        city: "",
        area: "",
        street: "",
        street_id: null,
      },
    };
  },

  async mounted() {
    let res = await GetPointList({
      page: this.page.toString(),
      limit: this.limit.toString(),
    });
    this.tableData = res.data.list;
    this.list_counts = res.data.list_counts;
    let res1 = await getGrantStreetList();

    this.StreetList = res1.data.list;
  },

  methods: {
    dialogVisibleToggle(statu) {
      this.dialogVisible = statu;
      if (statu) {
        this.areaData.province = "";
        this.areaData.city = "";
        this.areaData.street = "";
        this.areaData.area = "";
        this.editfromdata.address = "";
        this.formLabelAlign.address = "";
        this.areaData.street_id = null;
      }
    },

    //度分秒转经纬度
    degreeMinuteSecondConvertCoordinates(degree, minute, second) {
      return (
        Number(degree) +
        Number(minute / 60) +
        Number(second / 60 / 60)
      ).toFixed(8);
    },
    convertDegreesToDMS(degrees) {
      const d = Math.floor(degrees); // 取整得到度
      const md = Math.abs(degrees - d) * 60; // 剩余的分钟部分
      const m = Math.floor(md); // 取整得到分
      const s = ((md - m) * 60).toFixed(4); // 剩余的秒部分，四舍五入

      // 根据度数的正负决定方向（N/S 或 E/W）
      let direction = "";
      if (degrees < 0) {
        direction = "S"; // 假设这是纬度（南纬）
      } else {
        direction = "N"; // 假设这是纬度（北纬）
      }
      // 如果是经度，则替换为 'E' 或 'W'

      // 格式化输出
      // return `${d}°${m}'${s}" ${direction}`;
      return {
        d,
        m,
        s,
      };
    },
    pointWorkBreakSignShowFn(id) {},
    async handleSizeChange(val) {
      this.limit = val;
      let res = await GetPointList({
        page: this.page.toString(),
        limit: this.limit.toString(),
      });
      this.tableData = res.data.list;
    },
    async handleCurrentChange(val) {
      this.page = val;
      let res = await GetPointList({
        page: this.page.toString(),
        limit: this.limit.toString(),
      });
      this.tableData = res.data.list;
    },
    //添加设备
    addLaunchpoint() {
      let longitude = this.degreeMinuteSecondConvertCoordinates(
        this.EnclosureData.longitudeDegree,
        this.EnclosureData.longitudeBranch,
        this.EnclosureData.longitudeSecond
      );
      let latitude = this.degreeMinuteSecondConvertCoordinates(
        this.EnclosureData.latitudeDegree,
        this.EnclosureData.latitudeBranch,
        this.EnclosureData.latitudeSecond
      );

      const areaIds = this.$refs._Area.areaIds;

      if (!areaIds.street_id) {
        this.$message.error("请选择街道!");
        return;
      }

      if (isNaN(latitude) || isNaN(latitude)) {
        this.$message.error("错误的经纬度!");
        return;
      }

      let _barrel_types_arr = [];
      const arr = [this.num0, this.num1, this.num2, this.num3];
      arr.forEach((item, index) => {
        if (item > 0) {
          _barrel_types_arr.push(`${index + 1}=${item}`);
        }
      });

      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.dialogVisibleToggle(false);
          await addEditPoint({
            point_name: this.formLabelAlign.point_name,
            longitude: longitude,
            latitude: latitude,
            terminal_code: this.formLabelAlign.terminal_code,
            address: this.formLabelAlign.address,
            barrel_types: _barrel_types_arr.join(","),
            street_id: areaIds.street_id,
          });

          let res = await GetPointList({
            page: this.page.toString(),
            limit: this.limit.toString(),
          });
          this.tableData = res.data.list;
          this.list_counts = res.data.list_counts;
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.EnclosureData = {
            longitudeDegree: null, //经度的度
            longitudeBranch: null, //经度的分
            longitudeSecond: null, //经度的秒
            latitudeDegree: null, //纬度的度
            latitudeBranch: null, //纬度的分
            latitudeSecond: null, //纬度的秒
          };
        } else {
          return false;
        }
      });
    },
    //点击编辑投放点
    editLaunchpoint(row) {
      this.edit = true;
      this.editfromdata = JSON.parse(JSON.stringify(row));

      if (row.info) {
        this.areaData.province = row.info.province_name || "";
        this.areaData.city = row.info.city_name || "";
        this.areaData.area = row.info.area_name || "";
        this.areaData.street = row.info.street_name || "";
      }

      this.areaData.street_id = row.street_id || "";

      const lo = this.convertDegreesToDMS(row.longitude);
      // 经度
      this.EnclosureData.longitudeDegree = lo.d; // 度
      this.EnclosureData.longitudeBranch = lo.m; // 分
      this.EnclosureData.longitudeSecond = lo.s; // 秒
      const la = this.convertDegreesToDMS(row.latitude);
      // 纬度
      this.EnclosureData.latitudeDegree = la.d; // 度
      this.EnclosureData.latitudeBranch = la.m; // 分
      this.EnclosureData.latitudeSecond = la.s; // 秒

      row.barrel_info.forEach((item, index) => {
        this[`num${index}`] = item.is_set;
      });
    },
    //点击确定编辑投放点
    async seteditLaunchpoint() {
      console.log("点击确定编辑投放点");
      let longitude = this.degreeMinuteSecondConvertCoordinates(
        this.EnclosureData.longitudeDegree,
        this.EnclosureData.longitudeBranch,
        this.EnclosureData.longitudeSecond
      );
      let latitude = this.degreeMinuteSecondConvertCoordinates(
        this.EnclosureData.latitudeDegree,
        this.EnclosureData.latitudeBranch,
        this.EnclosureData.latitudeSecond
      );
      this.edit = false;

      let _barrel_types_arr = [];
      const arr = [this.num0, this.num1, this.num2, this.num3];
      arr.forEach((item, index) => {
        if (item > 0) {
          _barrel_types_arr.push(`${index + 1}=${item}`);
        }
      });

      let _street_id = this.$refs._Area.areaIds.street_id;

      // const areaIds = this.$refs._Area.areaIds;

      if (isNaN(_street_id)) {
        _street_id = this.areaData.street_id;
      }

      if (_street_id == "" || _street_id == null) {
        this.$message.error("请选择街道!");
        return;
      }

      if (isNaN(latitude) || isNaN(latitude)) {
        this.$message.error("错误的经纬度!");
        return;
      }

      const params = {
        point_name: this.editfromdata.point_name,
        point_id: this.editfromdata.point_id,
        street_id: _street_id,
        address: this.editfromdata.address,
        barrel_types: _barrel_types_arr.join(","),
        terminal_code: this.editfromdata.terminal_code,
        longitude,
        latitude,
      };

      await addEditPoint(params);
      let res = await GetPointList({
        page: this.page.toString(),
        limit: this.limit.toString(),
      });
      this.tableData = res.data.list;
      this.$message({
        message: "操作成功",
        type: "success",
      });
    },
    //点击地埋桶配置
    async clickbindid(row, type) {
      this.bindidisshow = true;
      let res = await getPointBarrelList({
        point_id: row.point_id,
        barrel_type: type,
      });
      let res1 = await getDeviceIdList({ type: 2 });
      this.bindiddata.barrel_idarr = res.data.list;
      this.nobingarr = res1.data;
    },
    //点击绑定/解绑点位id
    async setbindid() {
      await this.bindiddata.barrel_idarr.forEach((item) => {
        bindDeviceForBarrel({
          type: this.bindiddata.isbind,
          //投放点id
          barrel_id: item.barrel_id,
          //设备id
          device_id: item.device_id,
        });
      });
      this.$message({
        message: "操作成功",
        type: "success",
      });
      setTimeout(() => {
        this.getList();
      }, 200);
    },
    pointWorkBreakSignShowFn(id) {
      this.pointWorkBreakSignData.point_id = id;
      this.pointWorkBreakSignShow = true;
    },

    // 控制颜色
    handleControlColor(data) {
      const row = JSON.parse(JSON.stringify(data));
      console.log("rowrow", row);
      this.controlColorShow = true;

      this.controlColorData.hand_movement = row.hand_movement;
      this.controlColorData.old_hand_movement = row.hand_movement;
      this.controlColorData.point_full_status = row.point_full_status;
      this.controlColorData.point_id = row.point_id;

      console.log("controlColorData", this.controlColorData);
      // console.log("iddd", id);
    },
    async handleControlColorFnBB() {
      const arr = [
        changePointColor({
          point_id: this.controlColorData.point_id,
          point_full_status: this.controlColorData.point_full_status,
        }),
      ];

      if (
        this.controlColorData.old_hand_movement !==
        this.controlColorData.hand_movement
      ) {
        arr.unshift(
          setManualControl({
            point_id: this.controlColorData.point_id,
          })
        );
      }

      console.log("arrr", arr);

      Promise.all(arr).then((res) => {
        this.getList();
      });

      this.controlColorShow = false;
    },

    async pointWorkBreakSignFn() {
      let res = await pointWorkBreakSign(this.pointWorkBreakSignData);
      this.pointWorkBreakSignShow = false;
      this.getList();
      this.$message({
        message: "登记成功",
        type: "success",
      });
      this.pointWorkBreakSignData = {
        point_id: "",
        break_reason: "",
        type: 1,
      };
    },
    remove(id) {
      this.$confirm("确认删除该投放点？")
        .then(async () => {
          let res = await DeletePoint({
            point_id: id,
          });
          if (res.code == 1) {
            let res1 = await GetPointList({
              page: this.page.toString(),
              limit: this.limit.toString(),
            });
            this.tableData = res1.data.list;
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    async getList() {
      let res = await GetPointList({
        page: this.page.toString(),
        limit: this.limit.toString(),
        keywords: this.keywords,
        connect_status: this.connect_status,
        work_status: this.work_status,
        street_id: this.search_street_id,
      });
      this.tableData = res.data.list;
      this.list_counts = res.data.list_counts;
    },
  },
};
</script>

<style lang="less" scoped>
#test2 {
  width: 100%;
  height: 100vh;

  .crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
  }

  .center {
    width: 100vw;
    height: calc(100% - 31px);
    margin: auto;
    overflow: auto;
    box-sizing: border-box;
  }

  .abc {
    display: flex;
    flex-wrap: wrap;
    margin-left: 25px;
  }

  ::v-deep .el-radio {
    margin: 5px 0;
    margin-right: 20px;
  }

  .table {
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 0 10px;
  }

  .center_top {
    padding-top: 10px;
    padding-bottom: 13px;
    height: 36px;
    display: flex;
    justify-content: center;

    ::v-deep .el-input__inner {
      width: 190px;
      height: 32px;
    }
  }
}
</style>
